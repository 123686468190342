<template>
	<div>
		<div style="margin-top: 200px">
			<h2>选择地址</h2>
			lv选择几级分类：1/2/3   info是否展示详情输入框：true/false   size输入框大小:medium / small / mini   multiple是否开启多选:true/false
			<address-selector @change="selectCity" :address ="selectedAddress"  :lv="1" :info="true" size="small" :multiple="true"></address-selector>
			<p>选择的地址：{{ selectedAddress }}</p>
		</div>
	</div>
</template>

<script>
import AddressSelector from "../../components/common/city/AddressSelect";
export default {
	name: "index",
	components: {AddressSelector},
	computed:{},
	data() {
		return {
			selectedAddress: {
				cityId : [],
				cityName : [],
				address:""
			},
		};
	},
	methods: {
		selectCity(value) {
			this.selectedAddress = value;
		},
	},
};
</script>

<style scoped>

</style>
